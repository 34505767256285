<template>
  <div class="group" id="pageTop">
    <div class="content">
      <a-spin :spinning="loading">
        <div class="cards">
          <div class="head">
            <h5>{{ group.groupName }}</h5>
            <div v-if="group.groupId">
              <a-button
                v-if="
                  group.masterUid === userInfo.userId ||
                  group.adminUids.includes(userInfo.userId)
                "
                @click="$refs.modalInviteRef.openModal()"
                >{{ $t('bbs.invite_members') }}</a-button
              >
              <!-- 邀请成员 -->
              <a-input-search
                v-model:value="keyword"
                :placeholder="$t('bbs.please_enter_account_name_department_to_search')"
                style="width: 220px; margin-left: 16px"
                @search="onSearch"
              />
              <!-- 请输入账号/姓名/部门搜索 -->
            </div>
          </div>
          <div class="tab">
            <span :class="{ active: active === 1 }" @click="tabChange(1)"
              >{{ $t('bbs.has_joined') }}</span
            >
            <!-- 已加入 -->
            <span :class="{ active: active === 6 }" @click="tabChange(6)"
              >{{ $t('bbs.pending_review') }}</span
            >
            <!-- 待审核 -->
            <span :class="{ active: active === 2 }" @click="tabChange(2)"
              >{{ $t('bbs.has_rejected') }}</span
            >
            <!-- 已拒绝 -->
            <span :class="{ active: active === 20 }" @click="tabChange(20)"
              >{{ $t('bbs.has_invited') }}</span
            >
            <!-- 已邀请 -->
          </div>
          <div
            class="ctrl"
            v-if="
              active === 1 &&
              (group.masterUid === userInfo.userId ||
                group.adminUids.includes(userInfo.userId))
            "
          >
            <a-checkbox
              v-model:checked="checkAll"
              :indeterminate="indeterminate"
              @change="onCheckAllChange"
            >
              {{ $t('CM_SelectAll') }}</a-checkbox
            >
            <!-- 全选 -->
            <a-button class="btn" @click="delsMember">{{ $t('bbs.batch_remove') }}</a-button>
            <!-- 批量移除 -->
          </div>
          <div class="item" v-for="item in memberList" :key="item.userId">
            <div class="checkbox" v-if="active === 1">
              <a-checkbox
                v-model:checked="item.checked"
                :disabled="item.noChecked"
                @change="onCheckChange"
              />
            </div>
            <div
              class="info"
              :style="{ width: active === 2 ? '100%' : '870px' }"
            >
              <div class="info-top">
                <span class="name">
                  <OpenData type="userName" :openid="item.realName" />
                </span>
                <span class="department">
                  <OpenData
                    type="departmentName"
                    :openid="item.departmentName"
                  />
                </span>
                <tag v-if="item.isMaster === 1" text="圈主" />
                <tag v-if="item.isAdmin === 1" text="管理员" />
              </div>
              <div class="info-bottom" v-if="active === 1">
                <span class="account">{{ $t('cm_account') }}：{{ item.account }}</span>
                <!-- 账号 -->
                <span class="subjects">{{ $t('bbs.topic') }}：{{ item.subjects }}</span>
                <!-- 主题 -->
                <span class="time"
                  >{{ $t('bbs.join_time') }}：{{ dateFormat(item.joinTime) }}</span
                >
                <!-- 加入时间 -->
              </div>
              <div class="info-bottom" v-if="active === 6">
                <span class="account">{{ $t('cm_account') }}：{{ item.account }}</span>
                <!-- 账号： -->
                <span class="time"
                  >{{ $t('bbs.apply_time') }}：{{ dateFormat(item.applyTime) }}</span
                >
                <!-- 申请时间： -->
              </div>
              <div class="info-bottom" v-if="active === 2">
                <span class="account">{{ $t('cm_account') }}：{{ item.account }}</span>
                <!-- 账号： -->
                <span class="time"
                  >{{ $t('bbs.apply_time') }}：{{ dateFormat(item.applyTime) }}</span
                >
                <!-- 申请时间： -->
                <span class="time"
                  >{{ $t('bbs.process_time') }}：{{ dateFormat(item.auditTime) }}</span
                >
                <!-- 处理时间： -->
              </div>
              <div class="info-bottom" v-if="active === 20">
                <span class="account">{{ $t('cm_account') }}：{{ item.account }}</span>
                <!-- 账号： -->
                <span class="time"
                  >{{ $t('bbs.inviter') }}：<OpenData type="userName" :openid="item.inviteUser"
                /></span>
                <!-- 邀请人： -->
                <span class="time"
                  >{{ $t('bbs.invite_time') }}：{{ dateFormat(item.inviteTime) }}</span
                >
                <!-- 邀请时间： -->
              </div>
              <div
                class="info-opinion"
                v-if="(active === 1 || active === 2) && item.auditMind"
              >
              {{ $t('bbs.process_opinion') }}：{{ item.auditMind }}
                <!-- 处理意见： -->
              </div>
            </div>
            <div class="btns" v-if="active === 1">
              <template v-if="item.isMaster !== 1">
                <a-button v-if="!item.noChecked" @click="delMember(item.userId)"
                  >{{ $t('bbs.remove') }}</a-button
                >
                <!-- 移除 -->
                <a-button
                  v-if="
                    item.isAdmin !== 1 && group.masterUid === userInfo.userId
                  "
                  @click="setAdmin(item)"
                  >{{ $t('bbs.set_admin') }}</a-button
                >
                <!-- 设置管理员 -->
                <a-button
                  v-if="
                    item.isAdmin === 1 && group.masterUid === userInfo.userId
                  "
                  @click="delAdmin(item)"
                  >{{ $t('bbs.cancel_admin') }}</a-button
                >
                <!-- 取消管理员 -->
              </template>
            </div>
            <div
              class="btns"
              v-if="
                active === 6 &&
                (group.masterUid === userInfo.userId ||
                  group.adminUids.includes(userInfo.userId))
              "
            >
              <a-button @click="audit(item.userId, 1)">{{ $t('bbs.agree') }}</a-button>
              <!-- 同意 -->
              <a-button @click="audit(item.userId, 2)">{{ $t('bbs.reject') }}</a-button>
              <!-- 拒绝 -->
            </div>
            <div class="btns" v-if="active === 20">
              <span class="high" v-if="item.state === 3">{{ $t('bbs.has_invited') }}</span>
              <!-- 已邀请 -->
              <span v-if="item.state === 9">{{ $t('bbs.invite_successful') }}</span>
              <!-- 邀请成功 -->
            </div>
          </div>
          <a-empty v-if="!memberList.length" style="padding: 50px 0" />
          <div class="page-Wrap" v-if="memberList.length">
            <a-pagination
              hideOnSinglePage
              show-quick-jumper
              :defaultPageSize="10"
              v-model:current="currentPage"
              :show-total="(total) => $t('XB_InTotal', [pageTotal])"
              :total="pageTotal"
              @change="pageChange"
            >
              <template #itemRender="{ type, originalElement }">
                <a-button v-if="type === 'prev'">{{ $t('pre') }}</a-button>
                <a-button v-else-if="type === 'next'">{{ $t('next') }}</a-button>
                <renderVNode v-else :vnode="originalElement"></renderVNode>
              </template>
            </a-pagination>
          </div>
        </div>
      </a-spin>
    </div>
  </div>
  <a-modal
    v-model:visible="auditVisible"
    :title="auditTitle"
    width="550px"
    @ok="auditOk"
    :confirmLoading="auditLoading"
  >
    <a-textarea
      v-model:value="auditReason"
      :rows="4"
      showCount
      :maxlength="200"
      :placeholder="$t('bbs.please_enter_review')"
    />
    <!-- 请输入审核意见（选填） -->
  </a-modal>
  <modalInvite
    ref="modalInviteRef"
    :group="group"
    @handleOk="active === 20 && reload()"
  />
</template>

<script>
import { useI18n } from 'vue-i18n';
import {
  reactive,
  toRefs,
  computed,
  getCurrentInstance,
  createVNode,
} from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";

import { dateFormat } from "@/utils/tools";

import {
  getGroupDeatil,
  getGroupMembersList,
  groupMembersDel,
  groupSetAdmin,
  groupDelAdmin,
  auditGroupMember,
} from "@/api/bbs";
import { wxworkSearch, dingSearch } from "@/api/other";

import { Modal } from "ant-design-vue";
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";

import OpenData from "@/components/OpenData.vue";
import tag from "./components/tag.vue";
import modalInvite from "./components/modalInvite.vue";
const renderVNode = (_, { attrs: { vnode } }) => vnode;

export default {
  components: {
    OpenData,
    tag,
    modalInvite,
    renderVNode,
  },
  setup() {
    const { t: $t } = useI18n();
    const { proxy } = getCurrentInstance();
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const state = reactive({
      userInfo: computed(() => store.getters.userInfo),
      groupId: Number(route.query.groupId || 0),
      group: { adminUids: "" },
      memberList: [],
      loading: true,
      currentPage: 1,
      pageTotal: 1,
      active: 1,
      keyword: "",
      indeterminate: false,
      checkAll: false,
      canCheckedCount: 0,
      auditVisible: false,
      auditTitle: "",
      auditLoading: false,
      auditReason: "",
    });
    const platformConfig = computed(() => store.getters.platformConfig);

    getGroupDeatil(state.groupId).then((res) => {
      if (res.ret === 0) {
        state.group = res.data;
        getList(false);
      } else {
        router.replace({ path: "/bbs" });
      }
    });

    const getList = (scroll = false, accounts = []) => {
      state.checkAll = false;
      state.indeterminate = false;
      state.canCheckedCount = 0;
      state.loading = true;
      let params = {
        page: state.currentPage,
        pageSize: 10,
        state: state.active,
        keyword: state.keyword,
      };
      if (accounts.length) params.accounts = accounts;
      getGroupMembersList(state.groupId, params).then((res) => {
        state.loading = false;
        if (res.ret === 0) {
          state.pageTotal = res.data.totals;
          state.memberList = res.data.list || [];
          state.memberList.map((item) => {
            item.checked = false;
            if (state.group.masterUid === state.userInfo.userId) {
              if (item.isMaster === 1) {
                item.noChecked = true;
              } else {
                state.canCheckedCount++;
              }
            } else if (state.group.adminUids.includes(state.userInfo.userId)) {
              if (item.isMaster === 1 || item.isAdmin === 1) {
                item.noChecked = true;
              } else {
                state.canCheckedCount++;
              }
            } else {
              item.noChecked = true;
            }
          });
          if (scroll) {
            setTimeout(() => {
              document.getElementById("pageTop").scrollIntoView({
                behavior: "smooth",
                block: "start",
              });
            }, 0);
          }
        }
      });
    };

    const onSearch = () => {
      state.currentPage = 1;
      if (
        (platformConfig.value.platform == 26 ||
          platformConfig.value.platform == 31) &&
        state.keyword.trim() != ""
      ) {
        let searchAjax = wxworkSearch;
        if (platformConfig.value.platform == 31) searchAjax = dingSearch;
        searchAjax(state.keyword).then((res) => {
          getList(false, res.data.users || []);
        });
      } else {
        getList(false);
      }
    };

    const reload = () => {
      state.currentPage = 1;
      getList(false);
    };

    const tabChange = (val) => {
      state.active = val;
      state.currentPage = 1;
      getList(false);
    };

    const onCheckAllChange = (e) => {
      state.indeterminate = false;
      state.memberList.map((item) => {
        if (!item.noChecked) {
          item.checked = e.target.checked;
        }
      });
    };

    const onCheckChange = (e) => {
      if (e.target.checked === false) state.checkAll = false;
      let num = 0;
      state.memberList.map((item) => {
        if (!item.noChecked) {
          if (item.checked) num++;
        }
      });
      if (num === state.canCheckedCount) state.checkAll = true;
      state.indeterminate = !(num === 0 || num === state.canCheckedCount);
    };

    const delsMember = () => {
      let ids = [];
      state.memberList.map((item) => {
        if (item.checked) ids.push(item.userId);
      });
      if (!ids.length) {
        proxy.$message.warn($t('bbs.addpost.please_choose_person'));
        // 请选择人员
        return false;
      }
      Modal.confirm({
        title: () => $t('bbs.remove_selected_nth_people', [ids.length]),
        // 确定要移除选中的
        icon: () => createVNode(ExclamationCircleOutlined),
        onOk() {
          groupMembersDel(state.groupId, { ids }).then((res) => {
            if (res.ret === 0) {
              proxy.$message.success($t('CM_Success'));
              // 操作成功！
              reload();
            }
          });
        },
      });
    };

    const delMember = (id) => {
      Modal.confirm({
        title: () => $t('bbs.sure_remove'),
        // 确定要移除吗？
        icon: () => createVNode(ExclamationCircleOutlined),
        onOk() {
          groupMembersDel(state.groupId, { ids: [id] }).then((res) => {
            if (res.ret === 0) {
              proxy.$message.success($t('CM_Success'));
              // 操作成功！
              reload();
            }
          });
        },
      });
    };

    const setAdmin = (item) => {
      groupSetAdmin(state.groupId, { id: item.userId }).then((res) => {
        if (res.ret === 0) {
          proxy.$message.success($t('CM_Success'));
          // 操作成功！
          item.isAdmin = 1;
        }
      });
    };

    const delAdmin = (item) => {
      groupDelAdmin(state.groupId, { id: item.userId }).then((res) => {
        if (res.ret === 0) {
          proxy.$message.success($t('CM_Success'));
          // 操作成功！
          item.isAdmin = 0;
        }
      });
    };

    let auditUserId = 0,
      auditType = 1;
    const audit = (id, type) => {
      state.auditVisible = true;
      state.auditTitle = type === 1 ? $t('bbs.sure_agree') : $t('bbs.sure_reject');
      // "确定同意吗" : "确定拒绝吗"
      auditUserId = id;
      auditType = type;
    };

    const auditOk = () => {
      state.auditLoading = true;
      auditGroupMember({
        groupID: state.groupId,
        userID: auditUserId,
        audit: auditType,
        auditReason: state.auditReason,
      }).then((res) => {
        state.auditLoading = false;
        if (res.ret === 0) {
          state.auditReason = "";
          state.auditVisible = false;
          proxy.$message.success($t('CM_Success'));
          // 操作成功！
          reload();
        }
      });
    };

    const pageChange = (n) => {
      state.currentPage = n;
      getList(true);
    };

    return {
      dateFormat,
      ...toRefs(state),
      onSearch,
      reload,
      tabChange,
      onCheckAllChange,
      onCheckChange,
      delsMember,
      delMember,
      setAdmin,
      delAdmin,
      audit,
      auditOk,
      pageChange,
    };
  },
};
</script>

<style lang="less" scoped>
.group {
  padding: 22px 0 100px;
  .content {
    background-color: #fff;
    .mixinWrap();
    .cards {
      margin-bottom: 10px;
      min-height: 500px;
      .head {
        padding: 0 26px;
        height: 66px;
        .mixinFlex(space-between; center);
        border-bottom: 1px solid rgba(0, 0, 0, 0.06);
        h5 {
          font-size: 20px;
          font-weight: normal;
          color: #202020;
          margin: 0;
        }
        .sort {
          color: #666666;
          cursor: default;
        }
      }
      .tab {
        padding: 26px 26px 13px 26px;
        span {
          display: inline-block;
          font-size: 14px;
          line-height: 22px;
          margin-right: 40px;
          color: #202020;
          cursor: pointer;
          &.active {
            color: @color-theme;
          }
        }
      }
      .ctrl {
        padding: 13px 26px 6px;
        .btn {
          margin-left: 30px;
        }
      }
      .item {
        margin: 0 26px;
        padding: 20px 0;
        .mixinFlex(space-between; center);
        border-bottom: 1px solid rgba(0, 0, 0, 0.06);
        .checkbox {
          width: 20px;
          .mixinFlex(flex-start; center;);
        }
        .info {
          width: 870px;
          &-top {
            .mixinFlex(flex-start; center);
            height: 24px;
            margin-bottom: 10px;
            .name {
              font-size: 16px;
              color: #333;
              margin-right: 8px;
            }
            .department {
              font-size: 12px;
              color: #999;
              margin-right: 8px;
            }
          }
          &-bottom {
            .mixinFlex(flex-start; center);
            height: 22px;
            span {
              margin-right: 24px;
              font-size: 14px;
              line-height: 22px;
              color: #999;
              &.account {
                width: 220px;
                .mixinEllipsis(22px);
              }
              &.subjects {
                width: 70px;
              }
              &.time {
                width: 200px;
              }
            }
          }
          &-opinion {
            margin-top: 10px;
            font-size: 14px;
            color: #999;
            line-height: 20px;
            word-break: break-all;
          }
        }
        .btns {
          width: 240px;
          .mixinFlex(flex-end; center);
          button {
            margin-left: 12px;
          }
          span {
            font-size: 20px;
            color: #b3b3b3;
          }
          .high {
            color: @color-theme;
          }
        }
      }
    }
  }
}
</style>
