<template>
  <a-modal
    :title="group.groupName + '-' + $t('bbs.invite_members')"
    centered
    :width="620"
    :maskClosable="false"
    v-model:visible="visible"
    @ok="handleOk"
    @cancel="handleCancel"
    :ok-button-props="{ disabled: seleIds.length <= 0 }"
  >
    <div>
      <a-dropdown
        :getPopupContainer="(triggerNode) => triggerNode.parentNode"
        :trigger="['click']"
      >
        <a-input
          style="width: 450px"
          v-model:value="memberVal"
          :placeholder="$t('bbs.invite_members_tips')"
          @change="memberSearch"
        />
        <!-- 选择人员 -->
        <template #overlay>
          <a-menu style="max-height: 200px; overflow-y: auto" v-if="memberVal">
            <a-spin :spinning="memberLoading">
              <div style="min-height: 32px">
                <a-menu-item
                  v-for="item in memberList"
                  :key="item.userId"
                  @click="memberAdd(item)"
                >
                  <OpenData type="userName" :openid="item.realName" />
                  ({{ item.account }})
                </a-menu-item>
                <a-empty
                  v-if="!memberList.length && !memberLoading && memberVal"
                />
              </div>
            </a-spin>
          </a-menu>
        </template>
      </a-dropdown>
      <span class="tips">
        <template v-if="!seleUsers.length">{{
          $t("bbs.addpost.invite_up_to_nth_people", [50])
        }}</template>
        <!-- 最多邀请50位 -->
        <template v-else
          >{{ $t("XB_Personnel") }}（{{ seleUsers.length }}/50）</template
        >
        <!-- 人员 -->
      </span>
      <div class="tags">
        <a-tag
          color="rgba(0, 0, 0, 0.06)"
          v-for="(item, index) in seleUsers"
          :key="item.userId"
        >
          <OpenData type="userName" :openid="item.realName" />
          ({{ item.account }})
          <CloseOutlined @click="memberDel(index)" />
        </a-tag>
      </div>
    </div>
  </a-modal>
</template>

<script>
import { useI18n } from "vue-i18n";
import { reactive, toRefs, getCurrentInstance } from "vue";
import ls from "@/utils/local-storage";
import { debounce } from "lodash";
import { getAllMember, groupInviteJoinCheck, groupInviteJoin } from "@/api/bbs";
import { wxworkSearch, dingSearch } from "@/api/other";
import OpenData from "@/components/OpenData.vue";

export default {
  name: "modalInvite",
  components: {
    OpenData,
  },
  props: {
    group: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  emits: ["handleOk"],
  setup(props, { emit }) {
    const { t: $t } = useI18n();
    const { proxy } = getCurrentInstance();
    const state = reactive({
      platform: 0,
      useInternalWxWork: 0,
      visible: false,
      memberLoading: false,
      memberVal: "",
      memberList: [],
      seleUsers: [],
      seleIds: [],
    });

    const openModal = () => {
      document.body.click();
      state.platform = ls.get("platformConfig").platform;
      state.useInternalWxWork = ls.get("companyInfo").useInternalWxWork;
      state.memberVal = "";
      state.memberList = [];
      state.seleUsers = [];
      state.seleIds = [];
      state.visible = true;
    };

    const memberSearch = debounce(() => {
      if (state.memberVal.trim() == "") return false;
      state.memberLoading = true;
      if (state.platform == 26 || state.platform == 31) {
        let searchAjax = wxworkSearch;
        if (state.platform == 31) searchAjax = dingSearch;
        searchAjax(state.memberVal).then((res) => {
          getAllMember({
            page: 1,
            pageSize: 10,
            keyword: state.memberVal,
            accounts: res.data.users || [],
          }).then((res) => {
            state.memberLoading = false;
            state.memberList = res.data.list || [];
          });
        });
      } else {
        getAllMember({ page: 1, pageSize: 10, keyword: state.memberVal }).then(
          (res) => {
            state.memberLoading = false;
            state.memberList = res.data.list || [];
          }
        );
      }
    }, 500);

    const memberAdd = (data) => {
      if (state.seleIds.length >= 50) {
        proxy.$message.warn($t("bbs.select_most_people", [50]));
        // 最多选择50个人员
        return false;
      }
      if (state.seleIds.includes(data.userId)) {
        proxy.$message.warn($t("bbs.person_is_select"));
        // 已选择此人员
        return false;
      }
      groupInviteJoinCheck({
        groupID: props.group.groupId,
        userID: data.userId,
      }).then((res) => {
        if (res.ret === 0) {
          state.seleUsers.push(data);
          state.seleIds.push(data.userId);
          state.memberVal = "";
          state.memberList = [];
        }
      });
    };

    const memberDel = (index) => {
      state.seleUsers.splice(index, 1);
      state.seleIds.splice(index, 1);
    };

    const handleOk = () => {
      groupInviteJoin({
        groupID: props.group.groupId,
        userIDs: state.seleIds,
      }).then((res) => {
        if (res.ret === 0) {
          state.visible = false;
          proxy.$message.success($t("bbs.invite_successful"));
          // 邀请成功
          emit("handleOk");
        }
      });
    };

    const handleCancel = () => {
      state.visible = false;
    };

    return {
      ...toRefs(state),
      openModal,
      memberSearch,
      memberAdd,
      memberDel,
      handleOk,
      handleCancel,
    };
  },
};
</script>

<style lang="less" scoped>
.tips {
  font-size: 14px;
  line-height: 32px;
  color: #c4c4c4;
  padding-left: 28px;
}
.tags {
  margin-top: 8px;
  min-height: 100px;
  ::v-deep(.ant-tag) {
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.65);
    .anticon-close {
      color: rgba(0, 0, 0, 0.4);
    }
  }
}
</style>
